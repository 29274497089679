import React from 'react'
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import {device} from '../styles/Breakpoints'

import styled from 'styled-components';

import LazyLoad from "vanilla-lazyload";
import lazyloadConfig from "../config/lazyload";

const GalleryTileStyles = styled.div.attrs(props => ({
    className: 'gallery-item'
}))`
  position: relative;
  transition: .2s all ease-in-out;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    .hover-gif {
      display: block;
    }
    .gallery-item__image,
    video,
    .vimeo-thumbnail, 
    img {
      opacity: .6;
      filter: invert(1);
    }
    .gallery-item__title {
      opacity: 1;
    }
  }
  .gallery-item__link {
    display: block;
  }
  .mux__thumbnail {
    width: 100%;
  }
  .gatsby-image-wrapper img {
    max-width: 260px;
  }
  .gallery-item__title {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.2vw;
    padding: 12px;
    text-align: center;
    opacity: 0;
    transition: .2s all ease-in-out;
    @media ${device.$small} {
      font-size: 16px;
    }
  }
  .hover-gif {
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 0;
    display: none;
    pointer-events: none;
  }
  #galleryitem__video-thumbnail {
    max-width: 100%;
  }
  .vimeo-thumbnail {
    width: 100%;
    iframe {
      max-width: 100%;
      pointer-events: none;
      @media ${device.$small} {
        height: auto;
      }
    }
    p {
      display: none;
    }
  }
`




export class GalleryTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      test: this.props.galleryProps
    };
  }

  componentDidMount() {
    // if (!document.lazyLoadInstance) {
    //   document.lazyLoadInstance = new LazyLoad(lazyloadConfig);
    //   document.lazyLoadInstance.update();
    // }
  }

  componentDidUpdate() {
    // document.lazyLoadInstance.update();
  }

  render() {
    
    // TODO - add in check for feat image
    const thisGalleryData = this.props.galleryProps;
    // console.log('WTF', this.props.galleryProps)
    // console.log(`GALLERY TILE ${thisGalleryData.name} PROPS`, thisGalleryData);
    if (thisGalleryData.images.length) {

      const thumbnailImage = thisGalleryData.cover_image?.asset ? thisGalleryData.cover_image : thisGalleryData.images[0]
      const ogFluid = thumbnailImage.asset?.fluid;
      const customFluid = {};

      customFluid.aspectRatio = ogFluid.aspectRatio;
      customFluid.base64 = ogFluid.base64;
      customFluid.src = ogFluid.src;
      customFluid.srcSet = ogFluid.srcSet;
      customFluid.srcSetWebp = ogFluid.srcSetWebp;
      customFluid.srcWebp = ogFluid.srcWebp;
      customFluid.sizes = "(min-width: 768px) 380px, 190px";
      const ImageOrVideo = () => {
        if (thisGalleryData.enable_cover_video) {
          if (thisGalleryData.cover_video) {
            if (thisGalleryData.cover_video.mux_video) {
              const thumbnailURL = `https://image.mux.com/${thisGalleryData.cover_video.mux_video.asset.playbackId}/animated.gif?fps=24&end=10&height=200`
              return <img className="mux__thumbnail" src={thumbnailURL} loading="lazy" />
            }
            else {
              return (
                <video playsInline autoPlay muted loop id="galleryitem__video-thumbnail" className="lazy">
                  <source src={thisGalleryData.cover_video.video_file.asset.url} type={thisGalleryData.cover_video.video_file.asset.mimeType} ></source>
                </video>
                )
            }
            
          }
          else if (thisGalleryData.cover_video_vimeo) {
            return (
              <div className="vimeo-thumbnail" dangerouslySetInnerHTML={{ __html: thisGalleryData.cover_video_vimeo }}></div>
            )
          }
          
        }
        else {
          // return <Img fluid={customFluid} alt={thisGalleryData.name} className="gallery-item__image"/>
          return <img src={thumbnailImage.asset.fluid.src} srcSet={customFluid.srcSet} loading="lazy" sizes={customFluid.sizes}/>
        }
      }

  
      
      return <GalleryTileStyles>
              <Link to={`/galleries/${thisGalleryData.slug.current}`} className="gallery-item__link">
                  {ImageOrVideo()}
                  <div className="gallery-item__title">{thisGalleryData.name}</div>
              </Link>
              {
                this.props.galleryHoverGif && 
                <img className="hover-gif" src={this.props.galleryHoverGif} />
              }
              
            </GalleryTileStyles>
    }
    else {
      return <GalleryTileStyles>
        <Link to={`/galleries/${thisGalleryData.slug.current}`} className="gallery-item__link">
            <div className="gallery-item__title">{thisGalleryData.name}</div>
        </Link>
        {
          this.props.galleryHoverGif && 
          <img className="hover-gif" src={this.props.galleryHoverGif} />
        }
        
      </GalleryTileStyles>
    }
  }


}

export default GalleryTile;



// export default function GalleryTile(props) {

//     // TODO - add in check for feat image
//     const thisGalleryData = props.galleryProps;
//     // console.log(`GALLERY TILE ${thisGalleryData.name} PROPS`, thisGalleryData);
//     if (thisGalleryData.images.length) {

//       const thumbnailImage = thisGalleryData.cover_image?.asset ? thisGalleryData.cover_image : thisGalleryData.images[0]
//       const ogFluid = thumbnailImage.asset?.fluid;
//       const customFluid = {};

//       customFluid.aspectRatio = ogFluid.aspectRatio;
//       customFluid.base64 = ogFluid.base64;
//       customFluid.src = ogFluid.src;
//       customFluid.srcSet = ogFluid.srcSet;
//       customFluid.srcSetWebp = ogFluid.srcSetWebp;
//       customFluid.srcWebp = ogFluid.srcWebp;
//       customFluid.sizes = "(max-width: 768px) 190px, 380px";
//       const ImageOrVideo = () => {
//         if (thisGalleryData.enable_cover_video) {
//           if (thisGalleryData.cover_video) {
//             return (
//               <video playsInline autoPlay muted loop id="galleryitem__video-thumbnail" className="lazy">
//                 <source data-src={thisGalleryData.cover_video.video_file.asset.url} type={thisGalleryData.cover_video.video_file.asset.mimeType} ></source>
//               </video>
//               )
//           }
//           else if (thisGalleryData.cover_video_vimeo) {
//             return (
//               <div className="vimeo-thumbnail" dangerouslySetInnerHTML={{ __html: thisGalleryData.cover_video_vimeo }}></div>
//             )
//           }
          
//         }
//         else {
//           return <Img fluid={customFluid} alt={thisGalleryData.name} className="gallery-item__image"/>
//         }
//       }

  
      
//       return <GalleryTileStyles>
//               <Link to={`/galleries/${thisGalleryData.slug.current}`}>
//                   {ImageOrVideo()}
//                   <div className="gallery-item__title">{thisGalleryData.name}</div>
//               </Link>
//               {
//                 props.galleryHoverGif && 
//                 <img className="hover-gif" src={props.galleryHoverGif} />
//               }
              
//             </GalleryTileStyles>
//     }
//     else {
//       return <GalleryTileStyles>
//         <Link to={`/galleries/${thisGalleryData.slug.current}`}>
//             <div className="gallery-item__title">{thisGalleryData.name}</div>
//         </Link>
//         {
//           props.galleryHoverGif && 
//           <img className="hover-gif" src={props.galleryHoverGif} />
//         }
        
//       </GalleryTileStyles>
//     }
    
// }